<template>
  <div class="error-list-container">
    <h2 class="title">错题列表</h2>

    <div v-for="(item, index) in topics" :key="item.topicId" class="topic-item">
      <!-- <recursion-trunk :list="[item]"/> -->
      <div class="topic-trunk-wrap" style="display:flex;">
        <span style="line-height:1.5">{{index + 1}}、</span>
        <span class="topic-main" v-html="item.topicTrunk" />
      </div>
      <div v-if="item.items" class="items-wrap">
        <div v-for="p in item.items" :key="p.value" class="items">
          <span class="items-label">{{ p.value }}、</span>
          <span class="item-text" v-html="p.text" />
        </div>
      </div>

      <div v-if="item.childRepoTopics && item.childRepoTopics.length">
        <recursion-trunk :list="item.childRepoTopics" />
      </div>
    </div>

    <transition name="fade">
      <div v-if="loading" class="loading">
        正在加载...
      </div>
    </transition>
  </div>
</template>

<script>
import request from "../../request";
import RecursionTrunk from '../../components/RecursionTrunk'

export default {
  name: "ErrorList",
  components: { RecursionTrunk },
  data() {
    return {
      ids: null,
      token: null,
      topics: null,
      answers: null,
      loading: true
    }
  },
  created() {
    document.title = '错题列表'
    this.loadMathJax()
  },
  methods: {
    loadMathJax() {
      window.MathJax = {
        startup: {
          pageReady: () => {
            return window.MathJax.startup.defaultPageReady().then(() => {
              this.getErrorList()
            })
          }
        },
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'], ['\\[', '\\]']],
          displayMath: [['$$', '$$']]
        }
      }
      this.loadScript()
    },
    loadScript() {
      const script = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://pen.zwres.com/static-assets/mathJax/es5/tex-svg.js'
      head.appendChild(script)
    },
    setParams() {
      const params = this.$route.query
      this.ids = params.ids
      this.token = params.token
    },
    getErrorList() {
      this.setParams()
      request({
        url: `/api/repo-topic/topic-search/topic-ids/${this.ids}`,
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(response => {
        this.loading = false
        this.paper = response.data
        this.topics = response.data
        this.math()
        // this.setTopic(response.data)
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    math() {
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset()
        }
      })
    },
    setTopic(paper) {
      const loop = (child) => {
        child.children.forEach(node => {
          const type = node.nodeType
          const firstQuestion = child.nodeType === 'bigQuestion' && type !== 'bigQuestion'
          if (firstQuestion) {
            this.trimQuestion(node)
          } else if (type === 'bigQuestion') {
            node.topicId = node.nodeId || node.id
            const big = this.trimBig(node)
            this.topics.push(big)
            // 插入答案
            this.answers.push({
              id: node.id,
              name: node.nodeName,
              nodeType: node.nodeType,
              shortName: node.shortName
            })
            loop(node)
          }
        })
      }
      this.topics = []
      this.answers = []
      loop(paper)
    },
    trimQuestion(node) {
      // 设置主题干的序号（简称）
      node.content.trunkNumber = node.sort + '.'
      // 是否需要答题区标记
      let answerSpace = false
      // 如果题干为空，则把topicLevel等级设成0，方便子题计算
      if (node.nodeName === '') {
        node.topicLevel = 0
      }
      const loop = (child, index, parent) => {
        if (child.nodeType === 'smallQuestion') {
          return false
        }
        // 如果题干为空将不写入列表进行渲染
        if (child.nodeName !== '') {
          const level = parent ? parent.topicLevel + 1 : 1
          const topic = {
            id: child.id,
            itemId: child.topicId,
            nodeId: child.nodeId,
            trunk: child.nodeName,
            trunkNumber: child.content.trunkNumber,
            nodeType: child.nodeType,
            point: child.point,
            questionType: child.questionType,
            sort: child.sort,
            topicId: child.topicId,
            topicLevel: level, // 题目的层级
            typeId: child.typeId, // 题目类型ID
            choices: child.content.choices,
            topId: node.topicId, // 主题干的ID
          }
          // 如果本道题是解答题，且不是题组
          // 如果本道题是解答题且是题组，且题组下有某道子题是解答题类型
          // 以上两种情况需要加上答题区
          const noChild = node.questionType === 'other' && (!node.children || !node.children.length)
          const hasChild = parent && child.questionType === 'other'
          if (noChild || hasChild) {
            answerSpace = true
          }
          // 如果是叶子节点才允许切片
          if (child.nodeType === 'question' || child.nodeType === 'childQuestion') {
            topic.cut = true
          }
          // 如果父级题干为空，则第1个子题将继承父题干的序号
          if (parent && parent.nodeName === '' && !index) {
            topic.parentNumber = parent.content.trunkNumber
          }
          // 插入答案
          this.answers.push({
            id: child.id,
            answer: child.content.answer,
            questionType: child.questionType,
            nodeType: child.nodeType,
            typeId: node.typeId,
            level: level,
            trunkNumber: topic.trunkNumber,
            answerType: child.content.answerType
          })
          // 加入列表
          this.topics.push(topic)
        }
        if (child.children && child.children.length) {
          child.children.forEach((item, i) => {
            loop(item, i, child)
          })
        }
      }
      loop(node, null)
      // 如果本题需要答题区，则设置题目最后一个节点标记，方便在该节点下插入答题区
      if (answerSpace) {
        this.topics[this.topics.length - 1].last = true
      }
    },
    trimBig(node) {
      return {
        id: node.id,
        itemId: node.nodeId || node.id,
        itemExplain: node.itemExplain,
        nodeId: node.nodeId,
        nodeName: node.nodeName,
        nodeType: node.nodeType,
        point: node.point,
        shortName: node.shortName,
        sort: node.sort
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$fontSize: 10.5pt;
$fontFamily: FZSSK,serif;
$lineHeight: 1.6;
.error-list-container {
  padding: 20px;

  .title {
    text-align:center;
    margin-bottom: 40px;
  }

  .topic-item {
    margin: 10px 0;
    border-bottom: 1px dashed #333;


    .topic-main {
      font-size: 14px;
      font-weight: 400;
      color: #555;
      line-height: 1.8;
      line-break: anywhere;
      margin-bottom: 10px;
    }

    .items-wrap {
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .items {
      display: flex;
      margin-right: 15px;
      margin-bottom: 10px;
      ::v-deep .item-text p {
        margin: 0 !important;
      }
    }
  }

  .topic-trunk-wrap {
    display: flex;

    ::v-deep * {
      margin: 0;
      padding: 0;
    }
  }
}
</style>

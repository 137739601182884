<template>
  <div class="recursion-container">
    <div v-for="(item, index) in list" :key="index" class="list">
      <div class="topic-trunk">
        <div>{{ item.trunkNumber }}</div>
        <div v-if="item.topicTrunk" v-html="item.topicTrunk" />
      </div>

      <div v-if="item.children && item.children.length">
        <recursion-trunk :list="item.children" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecursionTrunk',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.topic-trunk {
  width: 100%;
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 1.8;
}
</style>
